exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-business-dashboards-tsx": () => import("./../../../src/pages/business/dashboards.tsx" /* webpackChunkName: "component---src-pages-business-dashboards-tsx" */),
  "component---src-pages-business-marketing-tsx": () => import("./../../../src/pages/business/marketing.tsx" /* webpackChunkName: "component---src-pages-business-marketing-tsx" */),
  "component---src-pages-business-tsx": () => import("./../../../src/pages/business.tsx" /* webpackChunkName: "component---src-pages-business-tsx" */),
  "component---src-pages-consulting-dashboards-tsx": () => import("./../../../src/pages/consulting/dashboards.tsx" /* webpackChunkName: "component---src-pages-consulting-dashboards-tsx" */),
  "component---src-pages-consulting-tsx": () => import("./../../../src/pages/consulting.tsx" /* webpackChunkName: "component---src-pages-consulting-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-education-dashboards-tsx": () => import("./../../../src/pages/education/dashboards.tsx" /* webpackChunkName: "component---src-pages-education-dashboards-tsx" */),
  "component---src-pages-education-data-support-tsx": () => import("./../../../src/pages/education/data-support.tsx" /* webpackChunkName: "component---src-pages-education-data-support-tsx" */),
  "component---src-pages-education-tsx": () => import("./../../../src/pages/education.tsx" /* webpackChunkName: "component---src-pages-education-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-researchlens-tsx": () => import("./../../../src/pages/researchlens.tsx" /* webpackChunkName: "component---src-pages-researchlens-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-resource-tsx": () => import("./../../../src/templates/resource.tsx" /* webpackChunkName: "component---src-templates-resource-tsx" */)
}

